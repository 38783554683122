<template>
  <div class="main">
    <div class="top">
      <div class="title">下载中心</div>
      <div class="right">
        <el-button
          size="small"
          type="primary"
          @click="getData"
          icon="el-icon-refresh"
        >
          刷新
        </el-button>
      </div>
    </div>
    <Page :search="false" :showTitle="false">
      <div class="table-wrap">
        <el-table border :data="tableData" v-loading="loading">
          <el-table-column
            header-align="center"
            align="center"
            show-overflow-tooltip
            prop="fileName"
            label="文件名称"
          />
          <el-table-column
            header-align="center"
            align="center"
            show-overflow-tooltip
            prop="createTime"
            label="时间"
          >
            <template slot-scope="{ row }">
              {{ new Date(row.createTime) | date('YYYY-mm-dd HH:MM:SS') }}
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            show-overflow-tooltip
            prop="status"
            label="处理状态"
          >
            <template slot-scope="{ row }">
              {{ status[row.status] || '-' }}
            </template>
          </el-table-column>

          <el-table-column
            header-align="center"
            show-overflow-tooltip
            label="操作"
            width="150"
          >
            <template slot-scope="{ row }">
              <el-button
                size="medium"
                type="text"
                v-auth="'down'"
                v-if="row.status == 3"
                @click="downloadFile(row)"
              >
                下载
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <Pagination
        :total="total"
        :page-size.sync="params.pageSize"
        :page.sync="params.pageNo"
        @change="getData"
      />
    </Page>
  </div>
</template>

<script>
import watchParamsGetData from '../../mixins/watchParamsGetData'
export default {
  components: {},
  data() {
    return {
      confirmVisible: false,
      status: {
        1: '待处理',
        2: '处理中',
        3: '处理完成',
        4: '处理失败'
      }
    }
  },
  mixins: [watchParamsGetData],
  methods: {
    async getData() {
      try {
        this.loading = true
        let params = { ...this.params }
        params.page = params.pageNo
        params.limit = params.pageSize
        params.pageNo = undefined
        params.pageSize = undefined
        const { list, total } = await this.$api.workOrder.getFileList(params)
        this.tableData = (list || []).map(item => {
          return { ...item }
        })
        this.total = total
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async downloadFile({ id = '', fileName = '' }) {
      try {
        if (id) {
          this.loading = true
          await this.$api.common.downloadExport({ id }, fileName)
          this.$message.success('下载成功')
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.main {
  background: #fff;
}
.top {
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
  border-bottom: 1px solid #f6f6f6;
}
</style>
